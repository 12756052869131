import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReadyScreen from './ReadyScreen';
import TurnScreen from './TurnScreen';
import ResultScreen from './ResultScreen';

const Game = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [wordBank, setWordBank] = useState([]);
  const [currentTeam, setCurrentTeam] = useState('A');
  const [scores, setScores] = useState({ A: 0, B: 0 });
  const [gameState, setGameState] = useState('loading'); // 'loading', 'ready', 'turn', 'result'

  useEffect(() => {
    const fetchWords = async () => {
      try {
        const difficulty = location.state?.difficulty || 'easy';
        const response = await fetch(`/words_${difficulty}.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch words');
        }
        const words = await response.json();
        const wordCount = location.state?.wordCount || 10;
        const shuffled = words.sort(() => 0.5 - Math.random());
        console.log(shuffled);
        setWordBank(shuffled.slice(0, wordCount));
        setGameState('ready');
      } catch (error) {
        console.error('Error fetching words:', error);
        // Handle the error appropriately, e.g., show an error message to the user
      }
    };

    fetchWords();
  }, [location.state]);

  const handleReady = () => {
    setGameState('turn');
  };

  const handleTurnEnd = (correct, newWordBank) => {
    const newScores = { ...scores, [currentTeam]: scores[currentTeam] + correct };
    setScores(newScores);
    setWordBank(newWordBank);
    
    if (newWordBank.length === 0) {
      setGameState('result');
    } else {
      setCurrentTeam(currentTeam === 'A' ? 'B' : 'A');
      setGameState('ready');
    }
  };

  const handleGameEnd = () => {
    navigate('/');
  };

  if (gameState === 'loading') {
    return <Box>Loading words...</Box>;
  }

  if (gameState == 'ready' && wordBank.length == 0) {
    setGameState('result');
  }

  return (
    <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
      {gameState === 'ready' && <ReadyScreen team={currentTeam} onReady={handleReady} />}
      {gameState === 'turn' && (
        <TurnScreen
          team={currentTeam}
          globalWordBank={wordBank}
          onTurnEnd={handleTurnEnd}
        />
      )}
      {gameState === 'result' && (
        <ResultScreen scores={scores} onGameEnd={handleGameEnd} />
      )}
    </Box>
  );
};

export default Game;