import React, { useState, useEffect } from 'react';
import { Box, Button, Text, VStack, HStack } from '@chakra-ui/react';

const TurnScreen = ({ team, globalWordBank, onTurnEnd }) => {
  const [timeLeft, setTimeLeft] = useState(60);
  const [currentWord, setCurrentWord] = useState('');
  const [correctWords, setCorrectWords] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [wordBank, setWordBank] = useState(globalWordBank);

  useEffect(() => {
    setCurrentWord(wordBank[Math.floor(Math.random() * wordBank.length)]);
  }, []);

  useEffect(() => {
    let timer;
    if (isTimerActive && timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      setIsTimerActive(false);
    }
    return () => clearTimeout(timer);
  }, [timeLeft, isTimerActive]);

  const handleSkip = () => {
    if (wordBank.length > 1 && isTimerActive) {
      setCurrentWord(wordBank.filter(w => w !== currentWord)[Math.floor(Math.random() * (wordBank.length - 1))]);
    } else {
      onTurnEnd(correctWords, wordBank);
    }
  };

  const handleNext = () => {
    const newCorrectWords = correctWords + 1;
    setCorrectWords(correctWords => correctWords + 1);
    const newWordBank = wordBank.filter(w => w !== currentWord);
    setWordBank(newWordBank);
    if (newWordBank.length >= 1 && isTimerActive) {
      setCurrentWord(wordBank.filter(w => w !== currentWord)[Math.floor(Math.random() * (wordBank.length - 1))]);
    } else {
      onTurnEnd(newCorrectWords, newWordBank);
    }
  };

  if (wordBank.length === 0) {
    return null;
  }

  return (
    <VStack spacing={4}>
      <Text fontSize="2xl">Team {team}'s Turn</Text>
      <Text fontSize="4xl">{currentWord}</Text>
      <Text fontSize="xl">Time left: {timeLeft > 0 ? timeLeft : "Time's up! Last chance!"}</Text>
      <HStack spacing={4}>
        <Button onClick={handleSkip} colorScheme="red">
          Skip
        </Button>
        <Button onClick={handleNext} colorScheme="green">
          Got it
        </Button>
      </HStack>
    </VStack>
  );
};

export default TurnScreen;