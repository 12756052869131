import React, { useState } from 'react';
import { Box, Button, Input, VStack, RadioGroup, Radio, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [wordCount, setWordCount] = useState('');
  const [difficulty, setDifficulty] = useState('easy');
  const navigate = useNavigate();

  const handleStart = () => {
    if (wordCount && parseInt(wordCount) > 0) {
      navigate('/game', { state: { wordCount: parseInt(wordCount), difficulty } });
    }
  };

  return (
    <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
      <VStack spacing={4}>
        <Text fontSize="2xl">Welcome to Hat Game</Text>
        <Input
          placeholder="Number of words"
          value={wordCount}
          onChange={(e) => setWordCount(e.target.value)}
          type="number"
        />
        <RadioGroup onChange={setDifficulty} value={difficulty}>
          <Stack direction="row">
            <Radio value="easy">Easy</Radio>
            <Radio value="hard">Hard</Radio>
          </Stack>
        </RadioGroup>
        <Button onClick={handleStart} colorScheme="blue">
          Start
        </Button>
      </VStack>
    </Box>
  );

};

export default Home;
