import React from 'react';
import { Box, Button, Text, VStack } from '@chakra-ui/react';

const ReadyScreen = ({ team, onReady }) => {
  return (
    <VStack spacing={4}>
      <Text fontSize="2xl">Team {team}'s Turn</Text>
      <Button onClick={onReady} colorScheme="green">
        Ready
      </Button>
    </VStack>
  );
};

export default ReadyScreen;
