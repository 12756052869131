import React from 'react';
import { Box, Button, Text, VStack } from '@chakra-ui/react';

const ResultScreen = ({ scores, onGameEnd }) => {
  const winner = scores.A > scores.B ? 'A' : scores.A < scores.B ? 'B' : 'Tie';

  return (
    <VStack spacing={4}>
      <Text fontSize="2xl">Game Over</Text>
      <Text fontSize="xl">Team A: {scores.A}</Text>
      <Text fontSize="xl">Team B: {scores.B}</Text>
      <Text fontSize="2xl">{winner === 'Tie' ? "It's a tie!" : `Team ${winner} wins!`}</Text>
      <Button onClick={onGameEnd} colorScheme="blue">
        Return to Home
      </Button>
    </VStack>
  );
};

export default ResultScreen;
